<template>
  <div class="view view--user">

    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
        <Container>
          <div class="grid grid--col-xs-6">
            <div class="tmp-col">
              <UserAvatar
                :image="data.avatar ?? null"
                :initials="helperService.userInitials(data.prenom, data.nom)"
                :color="data.couleur"
                size="large"
                class="h-margin-auto"
              />
            </div>
            <div class="tmp-col col-order-first">
              <KeyValue label="Prénom" :value="data.prenom" />
              <KeyValue label="Nom" :value="data.nom" />
              <KeyValue label="Identifiant" :value="data.identifiant" />
              <KeyValue label="Email" :value="data.email" />
              <KeyValue label="Téléphone" :value="data.telportable" />
            </div>
          </div>
        </Container>
      </Section>
    </div>

    <div class="view-footer">
      <div class="view-actions">
        <Btn
          :to="{ name: 'myAccountEdit' }"
          text="Modifier le profil"
          color="primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/user/UserAvatar.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'MyAccountView',

  components: {
    UserAvatar,
    KeyValue,
    Btn,
    Section,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      data: [],
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      this.fetchService.get(`utilisateur/${this.$store.state.auth.user.data.id}`)
        .then((response) => {
          this.data = response.data
        },
        (responseError) => {
          console.log('fetchError', responseError)
        })
    },
  },
}
</script>
